/* styles.css or your custom CSS file */

/* Swiper Container */
.swiper {
  width: 100%;
  height: 100%;
}

/* Swiper Slide */
.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50px; /* Example border radius */
}

/* Swiper Pagination */
.swiper-pagination {
  bottom: 10px;
  text-align: center;
}

.swiper-pagination-bullet {
  background: #131a21; /* Example bullet color */
}

.swiper-pagination-bullet-active {
  background: #131a21; /* Example active bullet color */
}

/* Swiper Navigation */
.swiper-button-next,
.swiper-button-prev {
  color: #131a21; /* Example navigation button color */
}

/* Swiper Autoplay Progress */
.swiper-progress {
  background: #131a21;
}

/* .swiper-button-prev,
.swiper-button-next {
  opacity: 0 !important;
  pointer-events: none !important;
} */
