@font-face {
  font-family: "f-bold";
  src: url("./assets/Neurial_Grotesk/NeurialGrotesk-Bold.otf");
  /* font-weight: bold; */
  font-style: normal;
}
@font-face {
  font-family: "f-extrabold";
  src: url("./assets/Neurial_Grotesk/NeurialGrotesk-Extrabold.otf");
  /* font-weight: bold; */
  font-style: normal;
}
@font-face {
  font-family: "f-light";
  src: url("./assets/Neurial_Grotesk/NeurialGrotesk-Light.otf");
  /* font-weight: bold; */
  font-style: normal;
}
@font-face {
  font-family: "f-medium";
  src: url("./assets/Neurial_Grotesk/NeurialGrotesk-Medium.otf");
  /* font-weight: bold; */
  font-style: normal;
}
@font-face {
  font-family: "f-normal";
  src: url("./assets/Neurial_Grotesk/NeurialGrotesk-Regular.otf");
  /* font-weight: bold; */
  font-style: normal;
}

body {
  font-family: f-normal;
}

.f-bold {
  font-family: f-bold;
}
.f-extrabold {
  font-family: f-extrabold;
}
.f-light {
  font-family: f-light;
}
.f-medium {
  font-family: f-medium;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #1B3C8E;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #1B3C8E;
  border-radius: 10px;
}

.noScroll::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.textTrunc {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.textTrunc2 {
  -webkit-line-clamp: 2;
}
.textTrunc3 {
  -webkit-line-clamp: 3;
}
.textTrunc4 {
  -webkit-line-clamp: 4;
}
.textTrunc5 {
  -webkit-line-clamp: 5;
}

/* input::placeholder {
  font-style: italic;
  font-size: 0.75rem; 
} */

.otp-code__input {
  border-radius: 0.5rem;
  font-family: monospace;
  height: 50px;
  border: 1px solid #ccc;
  font-size: 2rem;
  text-align: center;
  transition: all 0.2s ease;
  color: #222;
  width: 3rem !important;
  margin: 0 0.5rem;
}

.ql-editor{
  min-height:200px;
}